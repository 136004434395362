import React from 'react'

export default function FormStatus(props) {
  const { status } = props

  const baseStyle = { marginTop: '8px' }

  if (status === 'ERROR') {
    return (
      <p style={{ color: '#bc2323', ...baseStyle }}>
        Tapahtui virhe, eikä viestiä voitu lähettää.
      </p>
    )
  }

  if (status === 'SUCCESS') {
    return (
      <p style={{ color: 'rgb(48, 168, 48)', ...baseStyle }}>
        Kiitos, olemme vastaanottaneet viestisi onnistuneesti!
      </p>
    )
  }

  return null
}
