import React from 'react'
import styled from 'styled-components'

const Title = (props) => (
  <StyledTitle {...props} />
)

export default Title

const StyledTitle = styled.h5`
  font-family: 'Karla', sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: 30px;
  margin: 0px; 
  font-weight: 500;
  padding: 20px;
  &:after {
    display: block;
    clear: both;
    content: "";
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 250px;
    height: 1px;
    width: 100px;
    border-bottom: 3px solid rgb(0, 179, 207);
    margin:0 auto;
    padding:4px 0px;
  }
`
