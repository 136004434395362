import React from 'react'
import phoneIcon from '../../images/icons/phone-call.svg'
import phoneIconLight from '../../images/icons/phone-call-light.svg'
import emailIcon from '../../images/icons/email.svg'
import emailIconLight from '../../images/icons/email-light.svg'

function ContactDetails(props) {
  const {
    name, phone, email, address, rest,
    light,
  } = props

  return (
    <div style={{ marginBottom: '48px' }}>
      <h3>{name}</h3>
      {
        phone
        && (
          <p style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <img style={{ height: '24px', marginRight: '8px' }} src={light ? phoneIconLight : phoneIcon} alt="" />
            <a
              href={`tel:${phone}`}
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {phone}
            </a>
          </p>
        )
      }

      {
        email
        && (
          <p style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <img style={{ height: '24px', marginRight: '8px' }} src={light ? emailIconLight : emailIcon} alt="" />
            <a
              href={`mailto:${email}`}
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {email}
            </a>
          </p>
        )
      }

      {
        address && (
          <p style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <a
              href={`https://maps.google.com/?q=${address}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {address}
            </a>
          </p>
        )
      }

      {rest && (
        <p style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          {rest}
        </p>
      )}

    </div>
  )
}

export default ContactDetails
