import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const CollapseMenu = (props) => {
  const { navbarOpen, handleNavbar } = props

  const activeStyle = { borderBottom: '1px solid rgb(0, 179, 207)' }

  return (
    <CollapseWrapper style={{ height: navbarOpen ? '190px' : '0px' }}>
      <NavLinks>
        <li>
          <NavLink
            onClick={handleNavbar}
            to="/"
            exact
            activeStyle={activeStyle}
          >
            Etusivu
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleNavbar}
            to="/yhteystiedot"
            exact
            activeStyle={activeStyle}
          >
            Yhteystiedot
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleNavbar}
            to="/lomakkeet/yhteydenottopyyntö"
            exact
            activeStyle={activeStyle}
          >
            Yhteydenottopyyntö
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleNavbar}
            to="/lomakkeet/valitus"
            exact
            activeStyle={activeStyle}
          >
            Valitus
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleNavbar}
            to="/lomakkeet/irtisanomisilmoitus"
            exact
            activeStyle={activeStyle}
          >
            Irtisanomisilmoitus
          </NavLink>
        </li>
      </NavLinks>
    </CollapseWrapper>
  )
}

export default CollapseMenu

const CollapseWrapper = styled.div`
  background: #222222;
  z-index: 2;
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  transition: all .5s ease;
  overflow: hidden;
`

const NavLinks = styled.ul`
  list-style-type: none;

  & li {
    transition: all 300ms linear 0s;
    text-align: center;
  }

  & a {
    line-height: 2;
    color: #dfe6e9;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      font-weight: bold;
      border-bottom: 1px solid rgb(0, 179, 207);
    }
  }
`
