import React from 'react'
import styled from 'styled-components'
import Title from '../components/typography/Title'
import Button from '../components/Button'
import Input from '../components/Input'
import Textarea from '../components/Textarea'
import FormStatus from './FormStatus'

function ContactForm() {
  const [status, setStatus] = React.useState()

  const sendForm = (ev) => {
    ev.preventDefault()

    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        setStatus('SUCCESS')
        form.reset()
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }

  return (
    <Block>
      <ContentWrapper>
        <Title>
          Ota yhteyttä
        </Title>
        <div style={{ padding: '24px' }}>
          <form
            onSubmit={sendForm}
            action="https://formspree.io/xeqgoqje"
            method="POST"
          >
            <Label htmlFor="nimi">Nimi *</Label>
            <Input required id="nimi" type="text" name="Nimi" />
            <Label htmlFor="email">Sähköposti *</Label>
            <Input required id="email" type="email" name="Sähköposti" />
            <Label htmlFor="aihe">Aihe *</Label>
            <Input required id="aihe" type="text" name="Aihe" />
            <Label htmlFor="viesti">Viesti *</Label>
            <Textarea required id="viesti" type="text" name="Viesti" rows="5" />
            <Button type="submit">Lähetä</Button>
            <FormStatus status={status} />
          </form>
        </div>
      </ContentWrapper>
    </Block>
  )
}

export default ContactForm

const Label = styled.label`
  display: flex;
  color: #b5b5b5;
`

const Block = styled.div`
  width: 100%;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eee;
`

const ContentWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
`
