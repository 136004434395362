import styled from 'styled-components'

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  width: 100%;
  transition: all 0.3s;
  font-size: 18px;
  background-color: rgb(0, 179, 207);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export default Button
