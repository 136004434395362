import React, { lazy, Suspense } from 'react'
import {
  Route, Switch, BrowserRouter,
} from 'react-router-dom'
import styled from 'styled-components'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import GlobalStyle from './styles/Global'
import ScrollToTop from './ScrollToTop'
// import logoIconPng from './images/diretor_logo_musta.jpg'

import './App.css'

// import Home from './pages/Home'
// import Contact from './pages/Contact'
// import PrivacyPolicy from './pages/PrivacyPolicy'
// import Valitus from './forms/Valitus'
// import Irtisanomisilmoitus from './forms/Irtisanomisilmoitus'
// import Yhteydenottopyynto from './forms/Yhteydenottopyynto'

const Home = lazy(() => import('./pages/Home'))
const Contact = lazy(() => import('./pages/Contact'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const Valitus = lazy(() => import('./forms/Valitus'))
const Irtisanomisilmoitus = lazy(() => import('./forms/Irtisanomisilmoitus'))
const Yhteydenottopyynto = lazy(() => import('./forms/Yhteydenottopyynto'))

function App() {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const handleNavbar = () => {
    setNavbarOpen((current) => !current)
  }

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production' && (navigator && navigator.userAgent !== 'ReactSnap')) {
      const { location } = window
      if (location.hostname === 'innovest.fi') {
        location.replace('https://diretor.fi')
      } else if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`)
      }
    }
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar navbarOpen={navbarOpen} handleNavbar={handleNavbar} />
      <TopBarPadding />
      <Suspense fallback={(
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <picture>
            <img
              className="zoominoutsinglefeatured"
              style={{ width: '100px', marginTop: '166px', marginBottom: '150px' }}
              src="https://diretor-web.s3.eu-central-1.amazonaws.com/diretor_logo_musta.jpg"
              alt="Company Logo"
            />
          </picture>
        </div>
      )}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/yhteystiedot" component={Contact} />
          <Route exact path="/tietosuojalauseke" component={PrivacyPolicy} />
          <Route exact path="/lomakkeet/valitus" component={Valitus} />
          <Route exact path="/lomakkeet/irtisanomisilmoitus" component={Irtisanomisilmoitus} />
          <Route exact path="/lomakkeet/yhteydenottopyyntö" component={Yhteydenottopyynto} />
        </Switch>
      </Suspense>

      <Footer />
      <GlobalStyle />
    </BrowserRouter>
  )
}

export default App

const TopBarPadding = styled.div`
  background: rgb(34, 34, 34);
  @media (max-width: 768px) {
    height: 55px;
  }
  height: 100px;
`
