import React from 'react'
import styled from 'styled-components'

const Burgermenu = (props) => {
  const { handleNavbar, navbarOpen } = props

  return (
    <Wrapper onClick={handleNavbar}>
      <div className={navbarOpen ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Wrapper>
  )
}

export default Burgermenu

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  padding-right: 8px;

  & span {
    background: rgb(0, 179, 207);
    display: block;
    position: relative;
    width: 40px;
    height: 5px;
    margin-bottom: 6px;
    transition: all ease-in-out 0.2s;
  }

  .open span:nth-child(2) {
      opacity: 0;
    }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -11px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }

`
