import React from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import Brand from './Brand'
import BurgerMenu from './BurgerMenu'
import CollapseMenu from './CollapseMenu'

function Navbar(props) {
  const { navbarOpen, handleNavbar } = props

  const location = useLocation()

  const activeStyle = { borderBottom: '1px solid rgb(0, 179, 207)' }

  const navStyle = {
    backgroundColor: 'rgb(34, 34, 34)',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }

  const { pathname } = location
  const lomakkeetIsActive = pathname.includes('lomakkeet')

  return (
    <>
      <NavBar style={navStyle}>
        <FlexContainer>
          <Brand />
          <NavLinks>
            <NavLink
              exact
              to="/"
              activeStyle={activeStyle}
            >
              Etusivu
            </NavLink>
            <NavLink
              exact
              to="/yhteystiedot"
              activeStyle={activeStyle}
            >
              Yhteystiedot
            </NavLink>
            <Dropdown>
              <DropdownLink style={lomakkeetIsActive ? { borderBottom: '1px solid rgb(0, 179, 207)' } : {}}>
                Lomakkeet
              </DropdownLink>
              <DropdownContent>
                <NavLink
                  exact
                  to="/lomakkeet/yhteydenottopyyntö"
                  activeStyle={activeStyle}
                >
                  Yhteydenottopyyntö
                </NavLink>
                <NavLink
                  exact
                  to="/lomakkeet/valitus"
                  activeStyle={activeStyle}
                >
                  Valitus
                </NavLink>
                <NavLink
                  exact
                  to="/lomakkeet/irtisanomisilmoitus"
                  activeStyle={activeStyle}
                >
                  Irtisanomisilmoitus
                </NavLink>
              </DropdownContent>
            </Dropdown>

          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarOpen={navbarOpen}
              handleNavbar={handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarOpen={navbarOpen}
        handleNavbar={handleNavbar}
      />
    </>
  )
}

export default Navbar

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #222222;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;
`

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  @media (max-width: 768px) { 
    display: none;
  }
  &:hover ${DropdownContent} {
    display: block;
  }
`

const DropdownLink = styled.span`
  color: #dfe6e9;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  margin: 0 1.5rem;
  transition: all 0.6s linear 0s;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid rgb(0, 179, 207);
  }
`

const NavBar = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1rem;
  transition: background-color 0.6s ease;
`

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  justify-content: space-around;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`

const NavLinks = styled.div`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: #dfe6e9;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 0.6s linear 0s;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;

    &:hover {
      border-bottom: 1px solid rgb(0, 179, 207);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

`

const BurgerWrapper = styled.div`
  margin-top: 12px;

  @media (min-width: 769px) {
    display: none;
  }
`
