import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import logoIconPng from '../../images/logo_icon_100px.png'
// import logoIconWebp from '../../images/logo_icon_100px.webp'
import DiretorLogo2 from '../../images/logos/Diretor-logo-transparent2.png'

const Brand = () => (
  <Link to="/">
    <LargeImage src={DiretorLogo2} alt="Company Logo" />

    <div style={{ display: 'flex' }}>
      <picture>
        <source type="image/webp" srcSet="https://diretor-web.s3.eu-central-1.amazonaws.com/logo_icon_100px.webp" />
        <Image src="https://diretor-web.s3.eu-central-1.amazonaws.com/logo_icon_100px.jpg" alt="Company Logo" />
      </picture>
    </div>

  </Link>
)

export default Brand

const LargeImage = styled.img`
      width: auto;
      height: 95px;
      padding-left: 48px;
      margin-bottom: 5px;
      margin-top: 5px;
      margin: auto 0;
  @media (max-width: 768px) {
      display: none;
}
`

const Image = styled.img`
  @media (max-width: 768px) {
      display: block;
  width: auto;
  height: 45px;
  margin: auto 0;
  padding-left: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}
display: none;
`
