import styled from 'styled-components'

const Input = styled.input`
  background-color: transparent;
  color: inherit;
  width: 100%;
  padding: 16px 8px;
  margin-bottom: 16px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid grey;
  &:focus {
    outline: none;
    border-bottom: 2px solid rgb(0, 179, 207);
  }
`
export default Input
