import React from 'react'
import styled from 'styled-components'
import Title from '../typography/Title'

const Details = (props) => {
  const { title, content } = props

  return (
    <>
      <Title>
        {title}
      </Title>
      <ContentWrapper>
        {content}
      </ContentWrapper>
    </>
  )
}

export default Details

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 24px;
    // min-height: 300px;
`
