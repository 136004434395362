import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ContactForm from '../../forms/ContactForm'
import Details from '../content/Details'
import ContactDetails from '../contact/ContactDetails'
// import logoIcon from '../../images/logo_icon_100px.png'

const Footer = () => (
  <div>
    <div style={{
      marginTop: '16px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#222222',
      flexWrap: 'wrap',
    }}
    >
      <div style={{
        display: 'flex', width: '400px', flexDirection: 'column', color: 'white',
      }}
      >
        <Details
          title="Vuokravalvonta"
          content={(
            <div style={{ textAlign: 'center' }}>
              <ContactDetails
                light
                phone="0400 340 500"
                email="asiakaspalvelu@diretor.fi"
              />
            </div>
          )}
        />
        <Details
          title="Diretor Oy"
          content={(
            <div style={{ textAlign: 'center' }}>
              <picture>
                <source type="image/webp" srcSet="https://diretor-web.s3.eu-central-1.amazonaws.com/logo_icon_100px.webp" />
                <img src="https://diretor-web.s3.eu-central-1.amazonaws.com/logo_icon_100px.png" alt="Diretor logo" height="70px" />
              </picture>
              <ContactDetails
                light
                address="Kauppakatu 41 B 12, 40100 Jyväskylä"
                rest="Y-TUNNUS 2425834-8"
              />
            </div>
          )}
        />
        <Details
          title="Innovest Kiinteistöt Oy"
          content={(
            <div style={{ textAlign: 'center' }}>
              <ContactDetails
                address="Kauppakatu 41 B 12, 40100 Jyväskylä"
                rest="Y-TUNNUS 0794361-8"
              />
            </div>
          )}
        />
      </div>
      <div style={{ display: 'flex', width: '400px' }}>
        <ContactForm />
      </div>
    </div>
    <Top>
      <p style={{ color: 'rgb(234, 234, 234)', padding: '6px' }}>
        © Diretor Oy
      </p>
    </Top>
    <Bottom>
      <Link
        to="/tietosuojalauseke"
        style={{
          color: '#9e9e9e', padding: '4px', textDecoration: 'none', fontSize: '14px',
        }}
      >
        Tietosuojalauseke
      </Link>
    </Bottom>
  </div>
)

export default Footer

const Top = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444444;
`

const Bottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    color: #777777;
`
